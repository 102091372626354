<template>
  <div>
    <v-sheet class="d-flex justify-center"  >
      <v-sheet class="pt-8 px-6" width="782">
        <div class="pb-8 nunito">

          <h1  :style="`color: ${wsDARKER}`" class="font-weight-medium">Правила повернення коштів</h1>
          <h5 class="font-weight-light" :style="`color: ${wsACCENT}`">Редакція від 21 травня 2022 року</h5>
          <p class="mt-6">Комп‘ютерна програма «WeStudyTM Online» (далі може визначатися, як – «Платформа», «Система», «Ліцензійне програмне забезпечення», «Твір», «Комп‘ютерна програма», «Об‘єкт права інтелектуальної власності», «Об‘єкт інтелектуальної власності» чи «Об‘єкт авторського права») – спеціалізоване програмне забезпечення, набір інструкцій у вигляді слів, цифр, кодів, схем, символів чи у будь-якому іншому вигляді, виражених у формі, придатній для зчитування комп‘ютером, які приводять його у дію для досягнення певної мети або результату (прикладна програма, виражена у вихідному або об’єктному кодах)</p>

          <h3 :style="`color: ${wsDARKER}`" class="font-weight-medium mt-6" >I. Предмет повернення коштів</h3>
          <ol style="list-style-type: decimal;">

            <li>Якщо Послуги були оплачені Користувачем, але не надані з вини Адміністрації Платформи в установлений строк, Адміністрація на підставі письмової заяви такого Користувача, надає Користувачеві рівноцінну послугу. Відповідна заява разом з документами, що підтверджують факти і вимоги, зазначені Користувачем, повинно бути направлено поштою на електронну адресу Адміністрації, зазначений на Платформі. У разі, якщо вартість нової послуги перевищує вже оплачену Користувачем, Адміністрація має право вимагати від Користувача здійснення доплати різниці вартості послуг.</li>
            <li>При розгляді зазначених вище заяв в деяких випадках може виникнути необхідність повернення грошових коштів, сплачених Користувачем. У зв'язку з цим Користувач повинен зберігати листи, відправлені по електронній пошті Адміністрацією і відповідної Платіжною системою (банком), що підтверджують факт оплати, до моменту закінчення користування Послугою.</li>
            <li>Для розгляду заяви або претензії Користувача фінансового характеру, які повинні бути оформлені в письмовому вигляді, Адміністрація Платформи проводить ідентифікацію Користувача, а також може залучити Платіжну систему (банк), за допомогою яких було здійснено платіж, запросити документи, що підтверджують всі факти, викладені в заяві або претензії, а також паспортні дані Користувача у вигляді сканкопіі і банківські реквізити Користувача для здійснення повернення (повний перелік таких документів визначається по угледівши ію Адміністрації)</li>
            <li>При невиконанні цієї Угоди і вимог Адміністрації Платформи, остання має право відмовити Користувачеві в поверненні коштів або виплаті будь-яких компенсацій</li>
            <li>Користувач має право відмовитися від замовленої і оплаченої послуги, письмово повідомивши Адміністрацію Платформи, направивши Адміністрації письмове повідомлення. Після отримання письмового повідомлення Користувача, Адміністрація приймає рішення:</li>
            <ul style="list-style-type: square;">
              <li>при отриманні повідомлення, більш ніж за 1 робочий день до дати початку надання послуг, про повернення Користувачеві вартості оплачених, але не наданих послуг, з утриманням витрат на адміністративне обслуговування та обробку замовлення Користувача (10% від загальної вартості послуги);</li>
              <li>при отриманні повідомлення менш ніж за 1 робочий день до дати початку надання послуг, або не надання Користувачем документів, зазначених в п.2 Угоди, про відмову в поверненні коштів і виплати будь-яких компенсацій Користувачеві.</li>
            </ul>
            <li>Повернення коштів проводиться не пізніше, ніж через 5 робочих днів з моменту надходження відповідної заяви Користувача. У разі виникнення однієї із зазначених вище причин, Користувач звертається до Адміністрації Платформи поштою <a href="mailto:info@westudy.ua" target="_blank">info@westudy.ua</a>, з поміткою «Повернення коштів» і з проханням про повернення перерахованих коштів. До листа Користувач повинен прикріпити копію документа, що підтверджує його особу та підтвердження оплати. У разі повернення коштів через зміни обставин Користувача, Адміністрація Платформи повертає отримані від Користувача кошти, за вирахуванням податків і банківських комісій.</li>


          </ol>

        </div>
      </v-sheet>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "HomeAbout"
}
</script>

<style scoped>

</style>